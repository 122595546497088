import {get} from 'ys-admin'

/**
 *  获取当前用户的管辖区域
 * @param {Object} params
 * @returns {Object}
 */
export const GetValidArea = function (params) {
  return get('/user/GetValidArea', params)
};

/**
 *  获取当前用户的信息
 * @param {Object} params
 * @returns {Object}
 */
 export const userGetInfo = function (params) {
  return get('/user/GetInfo', params)
};
